<template>
  <div style="padding: 0">
    <navbar></navbar>
    <pageHowto></pageHowto>
  </div>
</template>

<script>
import pageHowto from '@/components/Howto/pageHowto'
import Navbar from "@/components/navbar";
// import navbarHowto from "@/components/Howto/navbarHowtoPage";
// import Footer from "@/components/footer";
export default {
  name: 'HowtoPage',
  components: {
    Navbar,
    // Footer,
    // navbarHowto,

    pageHowto,
  }
}
</script>
<style>

</style>
