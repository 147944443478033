<template>
  <div class="section Howtopage container-fluid" id="Howtopage">

<div class="offset-lg-2 col-lg-8 col-sm-12">
  <b-row >
    <b-col>
      <div class="list-group" id="accordion" >

        <a data-toggle="collapse" data-target="#about" aria-expanded="true" class="collapsed" >ABOUT COANY </a>

        <hr style="border: 1px solid #e0e6ea; width: 100%">
        <p class="sub-menu collapse" id="about" data-parent="#accordion">
        <b class="info text-center">Life Coach ในรูปแบบ Streaming คุณสามารถใช้แอปพลิเคชั่น CoAny ได้อย่างง่ายดาย เพียงไม่กี่ขั้นตอน</b>
          <br/>
          <br/>
          <vue-plyr >
            <video
                controls
                crossorigin
                playsinline
                :data-poster="this.poster_video"
            >
              <source
                  size="1080"
                  :src="this.video_about"
                  type="video/mp4"
              />
            </video>
          </vue-plyr>
        </p>


        <a data-toggle="collapse" data-target="#termAndcondition" aria-expanded="true" class="collapsed">TERMS & CONDITIONS </a>
        <hr style="border: 1px solid #e0e6ea; width: 100%">
        <p class="sub-menu collapse" id="termAndcondition"  data-parent="#accordion">
        <b class="info text-center">ข้อตกลงและเงื่อนไขการให้บริการ CoAny Application</b>
          <br/>
          <br/>
          <iframe class="info-iframe" src="https://terms.coany.co/" style="background-color: white" height="250" width="100%" title="TERM & CONDITION"></iframe>
        </p>

        <a data-toggle="collapse" data-target="#privacy" aria-expanded="true" class="collapsed" >PRIVACY</a>
        <hr style="border: 1px solid #e0e6ea; width: 100%">
        <p class="sub-menu collapse" id="privacy" data-parent="#accordion" style="padding-bottom: 100px">
         <b class="info text-center">บริษัทรวบรวมข้อมูลส่วนบุคคลเพื่อให้ผู้ใช้บริการแต่ละรายได้รับการบริการที่ดีอย่างมีประสิทธิภาพ</b>
          <br/>
          <br/>
          <iframe class="info-iframe" src="https://policy.coany.co/" style="background-color: white" height="250" width="100%" title="PRIVACY"></iframe>
        </p>

      </div>
    </b-col>
  </b-row>
</div>
    </div>
</template>
<style lang="scss">
  .Howtopage{
    justify-content: center;
    background-image: url("../../assets/background-howtopage.png");
    background-repeat: no-repeat;
  /*background-size: 100% auto;*/
    background-size: cover;
    background-position-y:50px ;
  /*background-position-x:50px ;*/
    position: relative;
    padding-top: 12vh;
  a{
    color: black;
    font-size: 30px;
  }
  .info{
    font-size: 14px;

    justify-content: center;
    text-align: center;
  }
  .nav-link{
    font-size: 18px;
  }
  [data-toggle="collapse"]:after {
    display: inline-block;
    font-family: "Font Awesome 5 Free", Kanit;
    font-weight: 900;
    font-size: 25px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f0d7";
    transform: rotate(180deg) ;
    transition: all linear 0.25s;
    float: right;
    position: relative;
    top: 3px;
  }
  [data-toggle="collapse"].collapsed:after {
    transform: rotate(0deg) ;
  }

  }
</style>
<script>
import video_about from '@/assets/Motion/motioncoany_facebook_07_sfxvoice_final.mp4'
import poster_video from '@/assets/VideoPoster.png'

export default {
  name: 'pageHowto',
  data () {
    return {
      video_about : video_about,
      poster_video:poster_video
    }
  },
  computed:{
    },
  components: {

  },
  methods:{

  }

}
</script>
