<template>
  <div class="container-fluid">
    <nav class="navbar smart-scroll navbar-expand-sm navbar-dark navbar-custom " id="navbar">
      <a @click="homepage"><img img src="../assets/logo.png"  class="navbar-brand"></a>
      <button class="navbar-toggler btn-hamberger" type="button" data-toggle="collapse" data-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-bars"></i>
      </button>
      <div class="collapse navbar-collapse nav justify-content-end" id="main_nav">
        <ul class="navbar nav">
          <li class="nav-item"><a class="nav-link active"  @click="homepage">HOME</a></li>
          <li class="nav-item"> <a class="nav-link" @click="howtopage">HOW TO</a></li>

          <b-button class="loginBtn" pill  style="color: #D70154;"  href="#lastpage" variant="light">DOWNLOAD</b-button>
        </ul>
      </div>

    </nav>
  </div>

</template>
<script>
// detect scroll top or down
var prevScrollpos = window.pageYOffset;
window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("navbar").style.top = "0";
  } else {
    document.getElementById("navbar").style.top = "-80px";
  }
  prevScrollpos = currentScrollPos;
}

export default {
  name:'navbar',
  data(){
    return{
    }
  },
  methods:{
    howtopage(){
      const path = `/howto`
      if (this.$route.path !== path) this.$router.push(path)
    },
    homepage(){
      const path = `/`
      if (this.$route.path !== path) this.$router.push(path)
    },
  }
}
</script>
<style lang="scss">


</style>